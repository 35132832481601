import(/* webpackMode: "eager", webpackExports: ["Layout"] */ "__barrel_optimize__?names=Layout!=!/app/node_modules/antd/es/index.js");
;
import(/* webpackMode: "eager" */ "/app/src/client/components/layouts/layout-app/styles.module.css");
;
import(/* webpackMode: "eager" */ "/app/src/client/components/page-elements/footer-private/footer-private.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["WithUser"] */ "/app/src/client/components/page-elements/private-page-store/with-user.ts");
;
import(/* webpackMode: "eager", webpackExports: ["TranslationsProvider"] */ "/app/src/client/contexts/translations-provider.tsx");
;
import(/* webpackMode: "eager" */ "/app/src/client/domains/user/consent-cookies/components/consent-cookies/consent-cookies.tsx");
