// Core
import { makeAutoObservable } from "mobx";

// Definitions
import { UserType } from "./types";

export type UserStoreType = {
  user: UserType | null;
  setUser(value: UserType | null): void;
};
const UserStore = () =>
  makeAutoObservable<UserStoreType>(
    {
      user: null,
      setUser(value) {
        this.user = value;
      },
    },
    {},
    { autoBind: true },
  );

export default UserStore;
