"use client";
// Core
import { ReactNode } from "react";

// Definitions
import type { UserType } from "@/client/domains/user/types";

// Hooks
import { useUser } from "@/client/domains/user/hooks/use-user";

export type WithProfileProps = {
  children: ReactNode;
  user?: UserType | null;
};

export const WithUser = (props: WithProfileProps) => {
  const { children, user } = props;
  const userStore = useUser();

  if (user) {
    userStore.setUser(user);
  }

  return children;
};
