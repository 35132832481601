// Core
import { ReactNode } from "react";
import { z } from "zod";

export const selectItemScheme = z.object({
  id: z.number(),
  value: z.string(),
  label: z.string(),
  icon: z.string().optional(),
  options: z
    .array(
      z.object({
        id: z.number(),
        value: z.string(),
        label: z.string(),
      }),
    )
    .optional(),
});
export const selectItemSchemeNullable = selectItemScheme.nullable();
export const selectItemAutocompleteSchema = selectItemScheme
  .omit({ label: true })
  .extend({
    label: z.string().or(z.custom<ReactNode>()).optional(),
  })
  .nullable();

export type SelectItem = z.infer<typeof selectItemSchemeNullable>;
export type SelectItemAutocomplete = z.infer<typeof selectItemAutocompleteSchema>;
