// Core
import { makeAutoObservable } from "mobx";

// Definitions
import { UserCountryCodesEnum, UserCountryCodesType } from "@/client/domains/user/types";

export type GeoType = {
  country: UserCountryCodesType;
  setCountry: (geo: UserCountryCodesType) => void;
};

const GeoStore = () =>
  makeAutoObservable<GeoType>(
    {
      country: UserCountryCodesEnum.fr,
      setCountry(lang: typeof this.country) {
        this.country = lang;
      },
    },
    {},
    { autoBind: true },
  );

export default GeoStore;
