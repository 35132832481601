// Core
import { makeAutoObservable } from "mobx";

export type SignupStepsStoreType = {
  activeStep: number | null;
  setActiveStep: (value: number | null) => void;
};

const SignupStepsStore = () =>
  makeAutoObservable<SignupStepsStoreType>(
    {
      activeStep: null,
      setActiveStep(value) {
        this.activeStep = value;
      },
    },
    {},
    { autoBind: true },
  );

export default SignupStepsStore;
