// Core
import { makeAutoObservable } from "mobx";
import { UploadFile, UploadFileStatus } from "antd/es/upload/interface";

export type MassSearchStoreType = {
  error: string;
  file: UploadFile | null;
  setFile: (value: UploadFile) => void;
  setPercent: (value: number) => void;
  setStatus: (value: UploadFileStatus) => void;
  setError: (value: string) => void;
};

const MassSearchStore = () =>
  makeAutoObservable<MassSearchStoreType>(
    {
      file: null,
      error: "",
      setFile(file) {
        this.file = { ...file };
      },
      setPercent(percent) {
        this.file = this.file && { ...this.file, percent };
      },
      setStatus(status) {
        this.file = this.file && { ...this.file, status };
      },
      setError(error) {
        this.error = error;
      },
    },
    {},
    { autoBind: true },
  );

export default MassSearchStore;
