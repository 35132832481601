// Core
import { makeAutoObservable } from "mobx";

// Definitions
import { Nullable } from "@/common/types/basic";

export type ControlType = {
  loading: boolean;
  error: Nullable<Error>;
  pageModals: Record<string, boolean>;
  permissions: Record<string, string>;
  toggleLoading: () => void;
  setAppError: (error: Nullable<Error>) => void;
  setOnPageModal: (name: string, visible: boolean) => void;
  flushModals: () => void;
  permissionGranted: (action: string) => boolean;
};

const ControlStore = () =>
  makeAutoObservable<ControlType>(
    {
      loading: false,
      error: null,
      permissions: {},
      pageModals: {},
      toggleLoading() {
        this.loading = true;
      },

      setAppError(err) {
        this.error = err;
      },

      setOnPageModal(name, visible) {
        this.pageModals[name] = visible;
      },

      flushModals() {
        this.pageModals = {};
      },

      permissionGranted(action) {
        return !!action;
      },
    },
    {},
    { autoBind: true },
  );

export default ControlStore;
